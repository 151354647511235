<template>
    <div id="team">
        <PageTitle :title="title" />
        <div class="team-container">
          <TeamMember
            v-for="(member, i) in team"
            :key="i"
            v-bind:image="member.image"
            v-bind:name="member.name"
            v-bind:title="member.title"
            v-bind:phone="member.phone"
            v-bind:email="member.email"
            v-bind:license="member.license"
            v-bind:description="member.description"
            />
        </div>
        <div class="image-block">
            <h3 class="description">
                We're a highly passionate team of professionals dedicated to helping you discover your dream property.
            </h3>
        </div>
    </div>
</template>

<script>
import TeamMember from '../components/TeamMember'
import PageTitle from '../components/PageTitle'

export default {
    name: "Team",
    components: {
      TeamMember,
      PageTitle
    },
    data(){
      return{
        title: "Our Team",
        team: [
            {
                image: '/images/about/team-paul.png',
                name: 'Paul Salazar',
                title: 'Luxury Estates Director',
                phone: '310.492.0737',
                email: 'paul@paulsalazargroup.com',
                license: 'DRE 01763227',
                description: `With over $550 million in total real estate sales and over 200 satisfied clients served, Paul Salazar is a real estate veteran with years of invaluable experience.<br><br>
                Paul's expertise encompasses the most sought after neighborhoods in Southern California, including Beverly Hills, Hollywood Hills, West Hollywood, Bel Air, Brentwood, Santa Monica, Pacific Palisades, Venice, Marina Del Rey, Playa Del Rey, and Malibu.<br><br>
                Partnered with one of the top luxury firms in the world, Hilton & Hyland, Paul has a string of $20+ million dollar properties sold under his belt, making him a true standout in this highly competitive and fast-paced industry. Paul is an expert in rebranding and relaunching properties that have been lingering on the market. He also works with developers to build luxury spec properties throughout Los Angeles.<br><br>
                One of the keys to Paul's success is his commitment and desire to meet and exceed his clients’ needs. Paul's focus on building relationships, providing comprehensive and personalized customer service, and using technology to facilitate the transaction process has provided him with a competitive edge in the business.<br><br>
                In addition to his own professional ambitions, Paul also aspires to help out other Realtors whenever possible. In fact, his team, Paul Salazar Group, has helped aspiring agents break into the luxury market. As a student of one of the top business coaches in the country, Paul, in his own capacity, also coaches other Realtors to become the best versions of themselves.<br><br>
                In college, Paul played NCAA Division 1 Soccer and did Semester at Sea which allowed him to visit 30 countries before earning his degree. His adventurous spirit persists today and when not tied up inking his multi-million dollar deals, he's out backpacking, climbing, and engaging in other extreme adventures. His proud Colombian roots allow him to connect with and appreciate various cultures throughout SoCal.<br><br>
                To strategically take advantage of today's real estate market or find your dream home, consult with Paul Salazar before your next move!`
            },
            {
                image: '/images/about/team-kyle.png',
                name: 'Kyle Amicucci',
                phone: '508.789.2261',
                email: 'kyle@paulsalazargroup.com',
                license: 'DRE 02063153',
                title: 'Luxury Sales Specialist',
                description: `Kyle is a real estate expert with the goal of providing the assistance needed for buyers and sellers to come together. He understands what is important to his clients, whether a first-time buyer or an experienced buyer, every client is treated equally and given top-notch service. Kyle's reputation for legendary customer service throughout every step of the transaction precedes him.<br><br>
                Buying or selling a home is a major decision; Kyle wants his clients to be satisfied with their purchase during and after the sale. He struts an innate ability to combine the big picture with painstaking attention to detail. Beginning his career as an integral member of a small real estate firm, Kyle sold over $40 million in real estate during his first year. Most notably, he assisted in the acquisition, redesign, and development of a $17 million property, turning it into a $29 million valuation in just under one year.
                `
            },
            {
                image: '/images/about/team-mario.png',
                name: 'Mario Reithaar',
                phone: '310.365.7042',
                email: 'mario@paulsalazargroup.com',
                license: 'DRE 02097247',
                title: 'Luxury Sales Specialist',
                description: `Originally born in Switzerland, Mario comes from three generations of LA Realtors. Mario has been passionate about real estate since his earliest years growing up in Sotogrande, Spain.<br><br>
                He also spent time living in London and has been a resident of Los Angeles since 2018. Mario had his first involvement in Real Estate as a university student managing a diverse residential property portfolio throughout London, adding to his international experiences. Mario uses his wealth of international experience and extensive local property knowledge to give his clients the upper hand.<br><br>
                Mario’s work ethic is unparalleled and promises each and every time to meet his clients satisfaction. Whether a real estate novice or seasoned investor, Mario believes in the philosophy of transparency. He says, “I feel creating something of value has to lead first with integrity, which then creates a solid foundation to do great business together.”<br><br>
                During his spare time, Mario can be found playing soccer and soaking up the sun on the beaches of Marina del Rey.`
            },
            {
                image: '/images/about/team-christian-hose.png',
                name: 'Christian Hose',
                phone: '310.490.8266',
                email: 'christian@paulsalazargroup.com',
                license: 'DRE 02218728',
                title: 'LUXURY ESTATES SPECIALIST',
                description: `Originally from London, Christian has over ten years of experience in the high-end luxury property markets across the UK, Dubai, and Los Angeles. His wealth of real estate expertise brought him to Hilton and Hyland, where he specializes in working with clients to find their dream homes and investment properties. His success is built on a highly personal approach to his clients: communication, integrity, and attention to detail are at the heart of every relationship he builds.<br><br>
                After graduating from the University of Durham in the UK, Christian has spent time exploring the Middle East and Europe both personally and professionally. Today, he draws inspiration from a diverse portfolio of international friends and clients. Outside of his passion for real estate, he keeps the spirit of mental sharpness and competitive edge alive through charitable marathons to raise money for good causes. When it comes to serving his clients, his goal is to meet and exceed his client's expectations by delivering a smooth and stress-free experience.<br><br>
                Christian currently resides in Santa Monica with his wife Brittany, who both love to travel and explore new places around the world.`
            },
            {
                image: '/images/about/team-caffrey.webp',
                name: 'Kate Caffrey',
                phone: '410.707.4935',
                email: 'kate@paulsalazargroup.com',
                license: 'DRE 02226200',
                title: 'Luxury Sales Specialist',
                description: `Kate is a luxury real estate expert joining Hilton & Hyland/Forbes Global Properties with a deep understanding of the LA market. Her success stems from a commitment to exceeding clients' expectations, building lasting relationships, offering personalized service, and leveraging technology to streamline the process. This client-first approach sets her apart, consistently delivering exceptional results and ensuring clients are genuinely happy with their real estate decisions.<br><br>
                A member of the Paul Salazar Group, Kate's experience includes working as a Project Manager with a luxury builder in Los Angeles, where she honed her expertise in construction, design, and development. Her real estate career was shaped by earlier roles in New York, where she excelled in buying positions at top firms like Bloomingdale's and NY & Co., gaining valuable exposure to the luxury sector. She later transitioned into the tech industry, where she led sales teams and launched new markets before ultimately discovering her true passion in real estate.<br><br>
                Kate's strategic thinking and negotiation skills enable her to provide unmatched, white-glove service to clients, whether they are buying, selling, or investing.`
            },
            {
                image: '/images/about/team-tamara-lin.png',
                name: 'Tamara Lin',
                title: 'SUPPORT MANAGER',
                phone: '858.472.6163',
                email: 'tamara@paulsalazargroup.com'
            },
            {
                image: '/images/about/team-austin.png',
                name: 'Austin Robbins',
                title: 'Marketing Consultant',
                phone: '424.253.0009',
            },
            {
                image: '/images/about/team-alex.png',
                name: 'Alex Lascus',
                title: 'Social Media Director',
            },
            {
                image: '/images/about/team-maggie.png',
                name: 'Maggie Ecklund',
                title: 'Transaction Coordinator',
                phone: '424.253.4092',
                email: 'maggie@paulsalazargroup.com',
            },
        ],
      }
    },
    metaInfo: {
        title: 'Team',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description',
            content: "Paul Salazar"
        }]
    },
}
</script>

<style lang="scss" scoped>
.team-container{
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 4rem auto;
  max-width: 1200px;
}
.image-block{
    background: url("/images/about/team-background.jpg");
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 750px;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    h3{
        color: #fff;
        opacity: 1;
        font-size: 60px;
        line-height: 80px;
        margin: 0 10%;
        font-weight: 100;
        text-align: center;
        letter-spacing: 2px;
        z-index: 2;
        padding: 0;
    }
}
@media (max-width: $mobile) {
    .image-block{
        margin-top: 0;
        height: 100vh;
        flex-direction: column;
        background-size: cover;
        background-attachment: unset;
        background-position: center;
        background-repeat: no-repeat;
        h3{
            color: #fff;
            letter-spacing: 2px;
            z-index: 2;
            padding: 0;
            margin: 0;
        }
        .description{
            line-height: 40px;
            font-size: 20px;
        }
    }
}
@media (max-height: 680px) {
    .image-block{
        margin-top: 0;
        height: 100vh;
        flex-direction: column;
        background-size: cover;
        background-attachment: unset;
        background-position: center;
        background-repeat: no-repeat;
        h3{
            color: #fff;
            letter-spacing: 2px;
            z-index: 2;
            padding: 0;
            margin: 0;
            width: 70%;
        }
        .description{
            line-height: 40px;
            font-size: 20px;
        }
    }
}
</style>