<template>
  <div class="team-member-container">
      <img :src="image" :alt="name">
      <h3 class="name">{{name}}</h3>
      <p class="title">{{title}}</p>
      <p v-if="license">{{license}}</p>
      <hr>
      <a v-if="phone" :href="`tel:+${phone}`">{{phone}}</a>
      <a v-if="email" :href="`mailto:${email}`">{{email}}</a>
      <p class="description" v-if="showMoreText" v-html="description">
      </p>
      <button v-if="description" class="btn" @click.prevent="toggleReadmore">
        {{ showMoreText ? 'Less' :'More'}}
      </button>
  </div>
</template>

<script>
export default {
    name: "Team",
    props: [
      'name', 'image', 'title', 'email','phone','license','description'
    ],
    data(){
      return{
        showMoreText: false
      }
    },
    methods:{
      toggleReadmore: function(){
          this.showMoreText = !this.showMoreText;
      },
    },
    computed:{
      readmoreText: function(){
        if(this.showMoreText){
          return this.description;
        } else {
          return this.description.substring(0, 300) + '...';
        }
      }
    }

}
</script>

<style lang="scss" scoped>
.team-member-container{
  margin: 2rem 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img{
    width: 40%;
    max-width: 180px;
  }
  h3, a, p{
    color: #fff;
    opacity: 1;
    font-weight: 500;
    font-size: 16px;
    margin: 2px 0;
  }
  hr{
    border: none;
    border-top: 1px solid #fff;
    opacity: 0.5;
    width: 200px;
    margin: 1rem 0;
  }
  .name{
    font-size: 20px;
    font-weight: 600;
    margin: 1rem;
  }
  .title{
    text-transform: uppercase;
  }
  .description{
    margin-top: 1.5rem;
    text-align: center;
    margin: 1.5rem 2rem 0;
    max-width: 500px;
    font-weight: 300;
  }

  button{
    color: #fff;
    display: inline-block;
    margin-top: 2rem;
    text-transform: uppercase;
    background: #000;
    padding: 10px 30px;
    border: 1px solid rgba(255,255,255,0.25);
    letter-spacing: 3px;
    font-size: 13px;
    font-weight: 600;
    &:hover{
      cursor: pointer;
    }
  }
  
}


@media (max-width: $mobile) {
  .team-member-container{
    width: 100%;
  }
}
</style>